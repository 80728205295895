import { GLOBAL_BREAKPOINTS } from './constants/breakpoints';

export const isTouchDevice = () => 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
export const isOnMobile = () => window.matchMedia(`(max-width: ${GLOBAL_BREAKPOINTS.b01 - 1}px)`).matches;
export const isOnMobileOrTablet = () => window.matchMedia(`(max-width: ${GLOBAL_BREAKPOINTS.b02 - 1}px)`).matches;
export const isOnDesktop = () => window.matchMedia(`(min-width: ${GLOBAL_BREAKPOINTS.b02}px)`).matches;
export const isOnTabletUp = () => window.matchMedia(`(min-width: ${GLOBAL_BREAKPOINTS.b01}px)`).matches;

export const addBodyScroll = className => document.body.classList.add(className);
export const removeBodyScroll = className => document.body.classList.remove(className);
export const toggleBodyScroll = className => document.body.classList.toggle(className);

export const errorHandler = message => {
  // eslint-disable-next-line no-console
  console.error('Fetch error: ', typeof message !== 'undefined' ? message : 'undefined');
};

// eslint-disable-next-line consistent-return
export const xmlHttp = (url, method, data) => {
  const http = new XMLHttpRequest();
  const headers = { name: 'Content-Type', value: 'application/json;charset=UTF-8' };

  return new Promise((resolve, reject) => {
    const { name, value } = headers;
    http.open(method, url);
    http.setRequestHeader(name, value);
    http.onload = () => {
      if (http.status >= 200 && http.status < 300) {
        return resolve(http.response);
      }
      return reject(http);
    };
    if (data) {
      http.send(data);
    } else {
      http.send();
    }
  });
};

export const toggleLoader = (loaderEl, classActive) => {
  if (loaderEl.classList.contains(classActive)) {
    loaderEl.classList.remove(classActive);
  } else {
    loaderEl.classList.add(classActive);
  }
};

export const scrollToTop = el => {
  el.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const getURLHash = () => window.location.hash;

export const getCookie = name => {
  const cookieName = `${name}=`;
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.indexOf(cookieName) === 0) {
          return cookie.substring(cookieName.length, cookie.length);
      }
  }
  return null;
}

export const setCookie = (name, value, days, domain, path) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = `expires=${date.toUTCString()};`;
  }
  const cookieDomain = domain ? `domain=${domain};` : '';
  document.cookie = `${name}=${(value || '')}; ${expires} ${cookieDomain} path=${(path || '/')}; SameSite=Lax; Secure`;
}

export const setCookieInSeconds = (name, value, seconds, domain, path) => {
  let expires = '';
  if (seconds) {
    const date = new Date();
    date.setTime(date.getTime() + (seconds * 1000));
    expires = `expires=${date.toUTCString()};`;
  }
  const cookieDomain = domain ? `domain=${domain};` : '';
  document.cookie = `${name}=${(value || '')}; ${expires} ${cookieDomain} path=${(path || '/')}; SameSite=Lax; Secure`;
}

export const getDaysValueFromDate = (date) => {
  const expirationDate = new Date(date);
  const currentDate = new Date();
  const timeDifference = expirationDate - currentDate;
  return timeDifference / (1000 * 60 * 60 * 24);
}

export const deleteCookie = (name) => {
  document.cookie = `${name}=; Max-Age=-99999999; path=/; SameSite=Lax; Secure`;
};

export const decodeJWT = (jwtToken) => {
  const parts = jwtToken.split('.');
  if (parts.length !== 3) {
    throw new Error('Invalid JWT format');
  }

  const decodedHeader = JSON.parse(atob(parts[0]));
  const decodedPayload = JSON.parse(atob(parts[1]));
  // Signature is typically the third part, which is not decoded as it is just a signature

  return {
      header: decodedHeader,
      payload: decodedPayload,
  };
}

// having a path to a json to get a value there
// e.g. data.collectionByHandle.products.edges[0].node.metafields

export const getValueByPath = (obj, path) =>
  path.split('.').reduce((value, key) => {
    let valueByPath = null;
    if (value && key.includes('[')) { // Handle array indices
      const [propertyName, indexStr] = key.split('[');
      const index = parseInt(indexStr.replace(']', ''), 10);
      valueByPath = Array.isArray(value[propertyName]) ? value[propertyName][index] : undefined;
    } else if (value) {
      valueByPath = Object.prototype.hasOwnProperty.call(value, key) ? value[key] : undefined;
    }
    return valueByPath;
  }, obj);

export const isAnObject = (value) => value?.constructor === Object;

export const waitForElement = (selector, callback) => {
  const observer = new MutationObserver((mutations, me) => {
    const element = document.querySelector(selector);
    if (element) {
      callback(element);
      me.disconnect();
    }
  });

  observer.observe(document, {
    childList: true,
    subtree: true,
  });
}

export const clickOutside = (element, callback) => {
  const handleClick = (event) => {
    if (!element.contains(event.target)) {
      callback();
    }
  }

  document.addEventListener('click', handleClick);

  return {
    remove: () => document.removeEventListener('click', handleClick),
  };
}
